export const sessionStorageKeys = {
  menuId: 'menu-id',
  name: 'name',
  phone: 'phone',
  notes: 'notes',
  deliveryZoneCost: 'delivery-zone-cost',
  primaryColor: 'primary-color',
  primaryColorBackground: 'primary-color-background',
  primaryColorBoxShadow: 'primary-color-box-shadow',
  homeUrl: 'home-url',
  appState: 'app-state',
  cartProducts: 'cart-products',
  pickUpTime: 'pick-up-time',
  deliveryZoneId: 'delivery-zone-id',
  payTeaser: 'pay-teaser',
  openTableId: 'open-table-id',
  orderNumbers: 'order-numbers',
  minimumOrderValue: 'minimum-order-Value',
};