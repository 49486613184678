import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BusinessState } from '../states/business.state';
import { environment } from '../../../environments/environment';
import { ActivatedModule } from '../enums/activated-module.enum';
import { serviceTypes } from '../constants/service-type';

@Injectable({ providedIn: 'root' })
export class BusinessFacade {
  public getBusiness = this._businessState.getBusiness.bind(
    this._businessState,
  );
  public fetchPreparationTimes = this._businessState.fetchPreparationTimes.bind(
    this._businessState,
  );
  public fetchDeliveryZones = this._businessState.fetchDeliveryZones.bind(
    this._businessState,
  );
  public fetchMenuProducts = this._businessState.fetchMenuProducts.bind(
    this._businessState,
  );
  public getMinimumOrderValue = this._businessState.getMinimumOrderValue.bind(
    this._businessState,
  );
  public fetchOrderingTimes = this._businessState.fetchOrderingTimes.bind(
    this._businessState,
  );
  public setBusinessStore = this._businessState.setBusinessStore.bind(
    this._businessState,
  );
  public setBusinessIsClosed = this._businessState.setIsBusinessClosed.bind(
    this._businessState,
  );
  public setCart = this._businessState.setCart.bind(this._businessState);

  public setSelectedMenuId = this._businessState.setselectedMenuId.bind(
    this._businessState,
  );
  public addCartProduct = this._businessState.addCartProduct.bind(
    this._businessState,
  );
  public resetCart = this._businessState.resetCart.bind(this._businessState);
  public putOrder = this._businessState.putOrder.bind(this._businessState);
  public finalizeOrder = this._businessState.finalizeOrder.bind(
    this._businessState,
  );
  public getBusinessConfiguration =
    this._businessState.getBusinessConfiguration.bind(this._businessState);

  public businessConfigurations$ = this._businessState.businessConfiguration$;
  public getCard = this._businessState.cart;
  public getSelectedMenuId = this._businessState.selectedMenuId;
  public business$ = this._businessState.business$;
  public menuProducts$ = this._businessState.menuProducts$;
  public orderingTimes$ = this._businessState.orderingTimes$;
  public preparationTimes$ = this._businessState.preparationTimes$;
  public deliveryZones$ = this._businessState.deliveryZones$;
  public serviceTypes$ = this._businessState.serviceTypes$;
  public orderResponse$ = this._businessState.orderResponse$;
  public minimumOrderValue$ = this._businessState.minimumOrderValue$;
  public cart = this._businessState.cart$;
  public selectedMenuid$ = this._businessState.selectedMenuId$;
  public store = this._businessState.store$;
  public deliveryIsPaused$ = this._businessState.deliveryIsPaused$;
  public takeAwayIsPaused$ = this._businessState.takeAwayIsPaused$;

  public businessIsOpen$ = combineLatest([this.serviceTypes$.hasData$]).pipe(
    map((values) => !values.includes(false)),
  );
  public businessIsClosed$ = this.businessIsOpen$.pipe(
    map((businessIsOpen) => !businessIsOpen),
  );

  public setDeliveryIsPaused(isPaused: boolean): void {
    this._businessState.setDeliveryIsPaused(isPaused);
  }

  public setTakeAwayIsPaused(isPaused: boolean): void {
    this._businessState.setTakeAwayIsPaused(isPaused);
  }
  constructor(
    private readonly _businessState: BusinessState,
    private _router: Router,
  ) {
    this._businessState.restoreState();
  }

  saveAppState(): void {
    this._businessState.saveSelectedState();
  }

  goToTakeAwayUrl(url: string): void {
    const storeSnapshot = this._businessState.store;
    const builtUrl = `/${environment.prePath}${storeSnapshot.businessType}/${storeSnapshot.city}/${storeSnapshot.businessName}/${storeSnapshot.businessId}/${serviceTypes[ActivatedModule.TakeAway].url}/${url}`;
    this._router.navigate([builtUrl]);
  }

  goToDeliveryUrl(url: string): void {
    const storeSnapshot = this._businessState.store;
    const builtUrl = `/${environment.prePath}${storeSnapshot.businessType}/${storeSnapshot.city}/${storeSnapshot.businessName}/${storeSnapshot.businessId}/${serviceTypes[ActivatedModule.Delivery].url}/${url}`;
    this._router.navigate([builtUrl]);
  }

  goToHotelServiceUrl(url: string): void {
    const storeSnapshot = this._businessState.store;
    const builtUrl = `/${environment.prePath}${storeSnapshot.businessType}/${
      storeSnapshot.city
    }/${storeSnapshot.businessName}/${storeSnapshot.businessId}/${serviceTypes[ActivatedModule.HotelService].url}/${storeSnapshot.qrCodeId}/${url}`;
    this._router.navigate([builtUrl]);
  }

  goToInhouseServiceUrl(url: string): void {
    const storeSnapshot = this._businessState.store;
    const builtUrl = `/${environment.prePath}${storeSnapshot.businessType}/${storeSnapshot.city}/${storeSnapshot.businessName}/${storeSnapshot.businessId}/${serviceTypes[ActivatedModule.Inhouse].url}/${storeSnapshot.qrCodeId}/${url}`;
    this._router.navigate([builtUrl]);
  }

  goToInhouseHome(url: string): string {
    let builtUrl = '';
    const storeSnapshot = this._businessState.store;
    builtUrl = `/${environment.prePath}${storeSnapshot.businessType}/${storeSnapshot.city}/${storeSnapshot.businessName}/${storeSnapshot.businessId}/${serviceTypes[ActivatedModule.Inhouse].url}/${storeSnapshot.qrCodeId}/${url}`;
    return builtUrl;
  }

  goToServiceHome(url: string, serviceType: ActivatedModule): string {
    let builtUrl = '';
    const storeSnapshot = this._businessState.store;
    builtUrl = `/${environment.prePath}${storeSnapshot.businessType}/${storeSnapshot.city}/${storeSnapshot.businessName}/${storeSnapshot.businessId}/${serviceTypes[serviceType].url}/${storeSnapshot.qrCodeId}/${url}`;

    return builtUrl;
  }

  goToInhouseUrl(url: string): void {
    const storeSnapshot = this._businessState.store;
    const builtUrl = `/${environment.prePath}${storeSnapshot.businessType}/${storeSnapshot.city}/${storeSnapshot.businessName}/${storeSnapshot.businessId}/${serviceTypes[ActivatedModule.Inhouse].url}/${storeSnapshot.qrCodeId}/${url}`;
    this._router.navigate([builtUrl]);
  }

  goToServiceUrl(url: string, serviceType: ActivatedModule): void {
    let builtUrl = '';
    const storeSnapshot = this._businessState.store;
    if (storeSnapshot.qrCodeId) {
      builtUrl = `/${environment.prePath}${storeSnapshot.businessType}/${storeSnapshot.city}/${storeSnapshot.businessName}/${storeSnapshot.businessId}/${serviceTypes[serviceType].url}/${storeSnapshot.qrCodeId}/${url}`;
    } else {
      builtUrl = `/${environment.prePath}${storeSnapshot.businessType}/${storeSnapshot.city}/${storeSnapshot.businessName}/${storeSnapshot.businessId}/${serviceTypes[serviceType].url}/${url}`;
    }
    this._router.navigate([builtUrl]);
  }

  goToInhouseConfirmationUrl(
    isPayFromQrCode: boolean = false,
    openTableId?: string,
  ): void {
    const storeSnapshot = this._businessState.store;
    const builtUrl = `/${environment.prePath}${storeSnapshot.businessType}/${storeSnapshot.city}/${storeSnapshot.businessName}/${storeSnapshot.businessId}/${serviceTypes[ActivatedModule.Inhouse].url}/${storeSnapshot.qrCodeId}/confirmation`;
    if (isPayFromQrCode) {
      this._router.navigate([builtUrl], {
        queryParams: {
          payFromQrCode: isPayFromQrCode,
          openTableId: openTableId,
        },
      });
    } else {
      this._router.navigate([builtUrl]);
    }
  }

  goToInhouseTipsUrl(
    isPayFromQrCode: boolean = false,
    openTableId?: string,
  ): void {
    const storeSnapshot = this._businessState.store;
    const builtUrl = `/${environment.prePath}${storeSnapshot.businessType}/${storeSnapshot.city}/${storeSnapshot.businessName}/${storeSnapshot.businessId}/${serviceTypes[ActivatedModule.Inhouse].url}/${storeSnapshot.qrCodeId}/tips`;
    if (isPayFromQrCode) {
      this._router.navigate([builtUrl], {
        queryParams: {
          payFromQrCode: isPayFromQrCode,
          openTableId: openTableId,
        },
      });
    } else {
      this._router.navigate([builtUrl]);
    }
  }

  goToHome(reload: boolean = false, serviceTypeModule: ActivatedModule): void {
    const storeSnapshot = this._businessState.store;
    let redirectUrl = `/${environment.prePath}${storeSnapshot.businessType}/${storeSnapshot.city}/${storeSnapshot.businessName}/${storeSnapshot.businessId}`;
    switch (serviceTypeModule) {
      case ActivatedModule.Inhouse:
        redirectUrl += `/${serviceTypes[ActivatedModule.Inhouse].url}/${storeSnapshot.qrCodeId}`;
        break;
      case ActivatedModule.HotelService:
        redirectUrl += `/${serviceTypes[ActivatedModule.HotelService].url}/${storeSnapshot.qrCodeId}`;
        break;
      case ActivatedModule.TakeAway:
        if (typeof storeSnapshot?.qrCodeId === 'number') {
          redirectUrl += `/${serviceTypes[ActivatedModule.TakeAway].url}/${storeSnapshot.qrCodeId}`;
        } else {
          redirectUrl += `/${serviceTypes[ActivatedModule.TakeAway].url}`;
        }
        break;
      default:
        break;
    }
    if (reload) {
      location.href = redirectUrl;
    } else {
      this._router.navigate([redirectUrl]);
    }
  }

  generatePaymentUrlsForServiceType(
    urlType: 'success' | 'cancel' | 'failed',
    serviceTypeModule: ActivatedModule,
    isPayFromQrCode: boolean = false,
    openTableId?: string,
  ): string {
    const store = this._businessState.store;

    let pathSuffix = '';
    if (urlType === 'success') {
      pathSuffix = 'confirmation';
    } else if (urlType === 'cancel' || urlType === 'failed') {
      if (serviceTypeModule === ActivatedModule.Inhouse) {
        pathSuffix = 'tips';
      } else {
        pathSuffix = 'checkout';
      }
    } else {
      throw new Error('Invalid URL type');
    }

    const baseUrl = `${window.location.origin}/${environment.prePath}`;
    const serviceTypeUrlPart = serviceTypes[serviceTypeModule]?.url || '';
    const serviceUrlPart = `${store.businessType}/${store.city}/${store.businessName}/${store.businessId}`;
    let fullUrl = `${baseUrl}${serviceUrlPart}`;

    if (serviceTypeUrlPart) {
      fullUrl += `/${serviceTypeUrlPart}`;
    }
    if (store.qrCodeId) {
      fullUrl += `/${store.qrCodeId}`;
    }

    fullUrl += `/${pathSuffix}`;

    if (isPayFromQrCode) {
      fullUrl += `?payFromQrCode=true&openTableId=${openTableId}`;
    }

    return fullUrl;
  }

  goToUrl(url: string): void {
    const decodedUrl = decodeURIComponent(url);
    this._router.navigate([decodedUrl]);
  }
}
