import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'orderapp-frontend-home-welcome',
  templateUrl: './home-welcome.component.html',
  styleUrls: ['./home-welcome.component.scss'],
})
export class HomeWelcomeComponent implements OnInit {
  @Input() isOpen: boolean = true;
  @Input() deliveryIsPaused: boolean = false;
  @Input() takeAwayIsPaused: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
