import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SessionService } from '../../../../../../../apps/orderapp/src/app/core/services/session.service';
import { sessionStorageKeys } from '../../../core/constants/session-storage-keys';

@Component({
  selector: 'orderapp-frontend-session-modal',
  templateUrl: './session-modal.component.html',
  styleUrls: ['./session-modal.component.scss'],
})
export class SessionModalComponent {
  @HostBinding('style.--primary-color') primaryColor =
    sessionStorage.getItem(sessionStorageKeys.primaryColor);
  @HostBinding('style.--primary-color-background') primaryColorBackground =
    sessionStorage.getItem(sessionStorageKeys.primaryColorBackground);
  @HostBinding('style.--primary-color-box-shadow') primaryColorBoxShadow =
    sessionStorage.getItem(sessionStorageKeys.primaryColorBoxShadow);

  public close() {
    this.onHandleResetSession();

    this._router.navigate([sessionStorage.getItem(sessionStorageKeys.homeUrl)]).then(() => {
      this.dialogRef.close();
      location.reload();
    });
  }

  constructor(
    public dialogRef: MatDialogRef<SessionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { url: string },
    private _sessionService: SessionService,
    private _router: Router,
  ) {}

  onHandleResetSession() {
    this._sessionService.resetSessionTimer();
    this._sessionService.resetWaringTimer();
    this._sessionService.resetInactivityTimer();
    this._sessionService.clearInterval();
    document.title = 'Jamatu: Scan & Order';
  }

  onContinue() {
    this.onHandleResetSession();
    this.dialogRef.close();
  }
}
