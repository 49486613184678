import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeTakeAwayComponent } from './modules/home/take-away/take-away.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { TakeAwayComponent } from './modules/take-away/take-away.component';
import { FoodMenuComponent } from './modules/food-menu/food-menu.component';
import { BeverageMenuComponent } from './modules/beverage-menu/beverage-menu.component';
import { ShoppingCartComponent } from './modules/shopping-cart/shopping-cart.component';
import { CheckoutComponent } from './modules/checkout/checkout.component';
import { ConfirmationComponent } from './modules/confirmation/confirmation.component';
import { environment } from '../environments/environment';
import { HomeInhouseComponent } from './modules/home/inhouse/inhouse.component';
import { HomeHotelServiceComponent } from './modules/home/hotel-service/hotel-service.component';
import { HotelServiceComponent } from './modules/hotel-service/hotel-service.component';
import { TipsComponent } from './modules/tips/tips.component';
import { DeliveryComponent } from './modules/delivery/delivery.component';
import { MenuSelectionComponent } from './modules/menu-selection/menu-selection.component';

const routes: Routes = [
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/:qrCodeId/menu-selection`,
    component: MenuSelectionComponent,
    pathMatch: 'full',
  },
  {
    path: `${environment.prePath}::businessType/:city/:businessName/:businessId/inhouse/:qrCodeId/menu-selection`,
    component: MenuSelectionComponent,
    pathMatch: 'full',
  },
  {
    path: `${environment.prePath}::businessType/:city/:businessName/:businessId/delivery/menu-selection`,
    component: MenuSelectionComponent,
    pathMatch: 'full',
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/menu-selection`,
    component: MenuSelectionComponent,
    pathMatch: 'full',
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/hotel-service/:qrCodeId/menu-selection`,
    component: MenuSelectionComponent,
    pathMatch: 'full',
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId`,
    component: HomeTakeAwayComponent,
    data: { animationState: 'Home', pickup: true },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away`,
    component: TakeAwayComponent,
    data: { animationState: 'TakeAway' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/food-menu`,
    component: FoodMenuComponent,
    data: { animationState: 'Food' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/beverage-menu`,
    component: BeverageMenuComponent,
    data: { animationState: 'Beverage' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/shopping-cart`,
    component: ShoppingCartComponent,
    data: { animationState: 'ShoppingCart' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/checkout`,
    component: CheckoutComponent,
    data: { animationState: 'Checkout' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/inhouse/:qrCodeId/checkout`,
    component: CheckoutComponent,
    data: { animationState: 'Checkout' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/confirmation`,
    component: ConfirmationComponent,
    data: { animationState: 'Confirmation' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/:qrCodeId`,
    component: HomeInhouseComponent,
    data: { animationState: 'Home' },
    pathMatch: 'full',
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/inhouse/:qrCodeId`,
    component: HomeInhouseComponent,
    data: { animationState: 'Home' },
    pathMatch: 'full',
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/hotel-service/:qrCodeId`,
    component: HomeHotelServiceComponent,
    data: { animationState: 'Home' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/hotel-service/:qrCodeId/pick-up`,
    component: HotelServiceComponent,
    data: { animationState: 'HotelService' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/inhouse/:qrCodeId/food-menu`,
    component: FoodMenuComponent,
    data: { animationState: 'Food' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/:qrCodeId/food-menu`,
    component: FoodMenuComponent,
    data: { animationState: 'Food' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/delivery/food-menu`,
    component: FoodMenuComponent,
    data: { animationState: 'Food' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/hotel-service/:qrCodeId/food-menu`,
    component: FoodMenuComponent,
    data: { animationState: 'Food' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/hotel-service/:qrCodeId/beverage-menu`,
    component: BeverageMenuComponent,
    data: { animationState: 'Beverage' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/inhouse/:qrCodeId/beverage-menu`,
    component: BeverageMenuComponent,
    data: { animationState: 'Beverage' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/:qrCodeId/beverage-menu`,
    component: BeverageMenuComponent,
    data: { animationState: 'Beverage' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/delivery/beverage-menu`,
    component: BeverageMenuComponent,
    data: { animationState: 'Beverage' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/:qrCodeId/shopping-cart`,
    component: ShoppingCartComponent,
    data: { animationState: 'ShoppingCart' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/delivery/shopping-cart`,
    component: ShoppingCartComponent,
    data: { animationState: 'ShoppingCart' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/inhouse/:qrCodeId/shopping-cart`,
    component: ShoppingCartComponent,
    data: { animationState: 'ShoppingCart' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/inhouse/:qrCodeId/tips`,
    component: TipsComponent,
    data: { animationState: 'Tips' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/:qrCodeId/tips`,
    component: TipsComponent,
    data: { animationState: 'Tips' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/hotel-service/:qrCodeId/shopping-cart`,
    component: ShoppingCartComponent,
    data: { animationState: 'ShoppingCart' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/:qrCodeId/checkout`,
    component: CheckoutComponent,
    data: { animationState: 'Checkout' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/delivery/checkout`,
    component: CheckoutComponent,
    data: { animationState: 'Checkout' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/hotel-service/:qrCodeId/checkout`,
    component: CheckoutComponent,
    data: { animationState: 'Checkout' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/inhouse/:qrCodeId/confirmation`,
    component: ConfirmationComponent,
    data: { animationState: 'Confirmation' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/:qrCodeId/confirmation`,
    component: ConfirmationComponent,
    data: { animationState: 'Confirmation' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/delivery/confirmation`,
    component: ConfirmationComponent,
    data: { animationState: 'Confirmation' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/hotel-service/:qrCodeId/confirmation`,
    component: ConfirmationComponent,
    data: { animationState: 'Confirmation' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/take-away/:qrCodeId`,
    component: TakeAwayComponent,
    data: { animationState: 'TakeAway' },
  },
  {
    path: `${environment.prePath}:businessType/:city/:businessName/:businessId/delivery`,
    component: DeliveryComponent,
    data: { animationState: 'TakeAway' },
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 58],
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
