<div>
  <div class="checkout__header">
    <div class="checkout__header--icon" (click)="goBack()">
      <i class="far fa-chevron-left"></i>
    </div>
    <p class="checkout__header--title">{{ 'checkout.title' | translate }}</p>
  </div>

  <div class="checkout__body" [formGroup]="form">
    <div>
      <!-- Name -->
      <mat-form-field class="" appearance="outline">
        <mat-label>{{ 'checkout.fullName' | translate }}</mat-label>
        <input matInput placeholder="John Doe" formControlName="name" />
        <mat-error *ngIf="form.controls['name'].invalid">{{
          'global.mandatoryValdationText' | translate
        }}</mat-error>
      </mat-form-field>

      <!-- Phone Number -->
      <mat-form-field appearance="outline">
        <mat-label>{{ 'checkout.phoneNumber' | translate }}</mat-label>
        <input matInput placeholder="+41" formControlName="phone" />
        <mat-error *ngIf="form.controls['phone'].invalid">{{
          'global.mandatoryValdationText' | translate
        }}</mat-error>
      </mat-form-field>

      <!-- Delivery address -->
      <mat-form-field *ngIf="serviceTypeModule === activatedModules.Delivery" appearance="outline">
        <mat-label>{{ 'checkout.streetNr' | translate }}</mat-label>
        <input
          matInput
          [placeholder]="'checkout.streetNr' | translate"
          formControlName="deliveryAddress"
        />
        <mat-error *ngIf="form.controls['deliveryAddress'].invalid">{{
          'global.mandatoryValdationText' | translate
        }}</mat-error>
      </mat-form-field>

      <!-- Delivery zone -->
      <mat-form-field *ngIf="serviceTypeModule === activatedModules.Delivery" appearance="outline">
        <mat-label>{{ 'delivery.selectZone' | translate }}</mat-label>
        <mat-select
          [(ngModel)]="selectedDeliveryZoneId"
          formControlName="deliveryZone"
          disableOptionCentering
          panelClass="deliveryZonesDD"
        >
          <mat-option
            *ngFor="
              let deliveryZone of businessFacade.deliveryZones$.data$ | async
            "
            [value]="deliveryZone.deliveryZoneId"
            (click)="changeCost(deliveryZone.cost)"
          >
            {{ deliveryZone.zipCode }} {{ deliveryZone.city }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Order Notes -->
      <mat-form-field appearance="outline">
        <mat-label>{{ 'checkout.orderNotes' | translate }}</mat-label>
        <textarea
          matInput
          [placeholder]="'checkout.orderNotesPlaceholder' | translate"
          formControlName="notes"
          #message
          rows="5"
          [maxLength]="180"
        ></textarea>
        <mat-hint align="end">{{ message.value.length }} / 180</mat-hint>
      </mat-form-field>
    </div>

    <div *ngIf="!isPickupTimeStillAvailable()  && serviceTypeModule === activatedModules.TakeAway">
      <div class="alert-info" role="alert">
        <div [innerHTML]="getTranslatedMessage()"></div>
      </div>
      <mat-form-field class="" appearance="outline">
        <mat-label>{{ 'takeaway.selectTime' | translate }}</mat-label>
        <mat-select
          [(ngModel)]="pickupTime"
          [ngModelOptions]="{ standalone: true }"
          (selectionChange)="onPickupTimeChange()"
        >
          <mat-option *ngFor="let time of timesToSelect" [value]="time">{{
            time
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="bottom-button-container" (click)="onContinue()">
    <button>
      <span
        *ngIf="businessFacade.orderResponse$.isLoading$ | async"
        class="loader"
      ></span>
      <span *ngIf="businessFacade.orderResponse$.isNotLoading$ | async">
        {{ 'global.orderButton' | translate }} - {{ currency }}
        {{ getTotalPrice | number: '1.2-2' }}
      </span>
    </button>
  </div>
</div>
