import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { PaymentDialogComponent } from '../shopping-cart/payment-dialog/payment-dialog.component';
import { CartProduct } from '../../core/models/cart.model';
import { BusinessStore } from '../../core/models/business-store.model';
import { serviceTypes } from '../../core/constants/service-type';
import { BusinessFacade } from '../../core/facades/business.facade';
import { catchError, filter, of, take } from 'rxjs';
import { ActivatedModule } from '../../core/enums/activated-module.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { FormatBusinessName } from '../../shared/utility/format-title';
import { environment } from 'apps/orderapp/src/environments/environment';
import { PaymentService } from '../../core/services/payment.service';
import { BusinessConfigurationService } from '../../core/services/business-configuration.service';
import { OrdersService } from '../../core/services/orders.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { OpenTableService } from '../../core/services/opentable-service';
import { BusinessService } from './../../core/services/business.service';
import {
  ExtraDto,
  OrderItemExtraRequestDto,
  OrderItemRequest,
  OrderRequest,
} from '@orderapp/api-clients/orderadmin-api-client';
import { getServiceModuleByUrl } from "../../shared/utility/get-activated-service-module";
import { sessionStorageKeys } from '../../core/constants/session-storage-keys';
import { PaymentOption } from '../../core/models/payment-option';
import { PaymentMethod } from '../../core/enums/payment-method.enum';
import { SWISS_CURRENCY } from '../../core/constants/currencies';

declare let jQuery: any;

enum TipsType {
  NO_TIP = 'noTip',
  AUTO = 'auto',
  PERCENTAGE = 'percentage',
  CUSTOM = 'custom',
}

@Component({
  selector: 'tips-page',
  templateUrl: 'tips.component.html',
  styleUrls: ['tips.component.scss'],
})
export class TipsComponent implements OnInit, OnDestroy {
  defaultAutoTipPercentage = 15;
  percentage = 15;
  tipsType = TipsType;
  businessStore: BusinessStore = {} as BusinessStore;
  cartProducts: CartProduct[] = [];

  selectedTip = TipsType.AUTO;
  serviceTypeModule: ActivatedModule = ActivatedModule.Inhouse;
  businessId!: string;

  totalTipValue = 0;
  customTipAmount = 0;

  isLoading = false;
  payFromQrCode = false;
  openTableId = '';
  isPayTeaser = false;
  currency: string = SWISS_CURRENCY;

  constructor(
    private bottomSheet: MatBottomSheet,
    private _location: Location,
    public readonly businessFacade: BusinessFacade,
    public readonly paymentService: PaymentService,
    private zone: NgZone,
    private route: ActivatedRoute,
    private businessConfigurationservice: BusinessConfigurationService,
    public readonly ordersService: OrdersService,
    private readonly openTableService: OpenTableService,
    private businessService: BusinessService,
    private router: Router,
  ) {
    this.serviceTypeModule = getServiceModuleByUrl(location.pathname);
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem(sessionStorageKeys.name);
    sessionStorage.removeItem(sessionStorageKeys.phone);
    sessionStorage.removeItem(sessionStorageKeys.notes);
  }

  ngOnInit(): void {
    this.payFromQrCode =
      Boolean(this.route.snapshot.queryParamMap.get('payFromQrCode')) ?? false;
    this.isPayTeaser = Boolean(sessionStorage.getItem(sessionStorageKeys.payTeaser));
    this.openTableId =
      this.route.snapshot.queryParamMap.get('openTableId') ?? '';
    if (this.isPayTeaser) {
      this.openTableId = sessionStorage.getItem(sessionStorageKeys.openTableId) ?? '';
    }
    this.route.params.subscribe((params) => {
      this.handleRouteParams(params as BusinessStore);
    });
    this.businessFacade.cart.subscribe((data) => {
      this.cartProducts = data;
      if (this.cartProducts.length === 0) {
        switch (this.serviceTypeModule) {
          case ActivatedModule.Inhouse || ActivatedModule.HotelService:
            this.businessFacade.goToUrl(
              this._location.path().replace('/tips', ''),
            );
            break;
          case ActivatedModule.TakeAway:
            if (this.businessStore.qrCodeId != null) {
              this.businessFacade.goToUrl(
                this._location
                  .path()
                  .replace(
                    '/take-away/' + this.businessStore.qrCodeId + '/tips',
                    '',
                  ),
              );
            } else {
              this.businessFacade.goToUrl(
                this._location.path().replace('/take-away/tips', ''),
              );
            }
            break;
          case ActivatedModule.Delivery:
            this.businessFacade.goToUrl(
              this._location.path().replace('/delivery/tips', ''),
            );
            break;
          default:
            break;
        }
      }
    });
    if (this.businessStore.businessId) {
      document.title = FormatBusinessName(
        this.businessStore.businessName,
        'Warenkorb',
      );
      this.businessId = this.businessStore.businessId;
      this.businessStore = {
        businessId: this.businessStore.businessId,
        businessName: this.businessStore.businessName,
        businessType: this.businessStore.businessType,
        city: this.businessStore.city,
        qrCodeId: this.businessStore.qrCodeId,
        // menuId: 0, //this.businessStore.menuId,
        serviceTypeId: this.businessStore.serviceTypeId,
      };
    }
    this.businessConfigurationservice
      .getBusinessConfiguration(this.businessId)
      .pipe(
        catchError((err) => {
          //log error
          return of(null);
        }),
      )
      .subscribe((res) => {
        if (res && res.inhouseConfiguration !== null) {
          this.defaultAutoTipPercentage =
            res.inhouseConfiguration?.automaticTipPercentage || 15;
        }
        this.calculateTip();
      });
  }

  calculateTip() {
    switch (this.selectedTip) {
      case this.tipsType.NO_TIP:
        this.totalTipValue = 0;
        break;
      case this.tipsType.AUTO:
        this.totalTipValue =
          (this.getTotalPriceFn() * this.defaultAutoTipPercentage) / 100; // 15% tip
        break;
      case this.tipsType.PERCENTAGE:
        this.totalTipValue = (this.getTotalPriceFn() * this.percentage) / 100;
        break;
      case this.tipsType.CUSTOM:
        this.totalTipValue = this.customTipAmount; // Assuming customTipAmount is defined
        break;
      default:
        this.totalTipValue = 0;
        break;
    }
  }

  onPercentageChange(newPercentage: number) {
    this.percentage = newPercentage;
    this.calculateTip();
  }

  private handleRouteParams(params: BusinessStore): void {
    const businessStore = params as BusinessStore;
    this.businessFacade.setBusinessStore(businessStore);
    if (this.serviceTypeModule === ActivatedModule.Inhouse) {
      sessionStorage.setItem(
        sessionStorageKeys.homeUrl,
        `/${environment.prePath}${businessStore.businessType}/${businessStore.city}/${businessStore.businessName}/${businessStore.businessId}/inhouse/${businessStore.qrCodeId}`,
      );
    } else if (this.serviceTypeModule === ActivatedModule.TakeAway) {
      sessionStorage.setItem(
        sessionStorageKeys.homeUrl,
        `/${environment.prePath}${businessStore.businessType}/${businessStore.city}/${businessStore.businessName}/${businessStore.businessId}/take-away/${businessStore.qrCodeId}`,
      );
    }
    const cartProducts: CartProduct[] = JSON.parse(
      sessionStorage.getItem(sessionStorageKeys.cartProducts) ?? '[]',
    );
    this.businessFacade.setCart(cartProducts);
    this.businessStore = params as BusinessStore;
  }

  onChangeTips(event: MatButtonToggleChange) {
    this.selectedTip = event.value;
    this.calculateTip();
  }

  openDialog(): void {
    let paymentOptions = [{
      method: PaymentMethod.Card,
      translationKey: 'paymentmethod.online'
    }] as PaymentOption[];

    if (!this.isPayTeaser) {
      paymentOptions.unshift({
        method: PaymentMethod.Cash,
        translationKey: 'paymentmethod.cash'
      });
    }

    const bottomSheetRef = this.bottomSheet.open(PaymentDialogComponent, {
      data: paymentOptions,
    });

    bottomSheetRef.afterDismissed().subscribe((result) => {
      this.continueOrder(result.paymentMethod);
    });
  }

  goBack(): void {
    this.businessFacade.goToUrl(
      this.router.url.replace('/tips', '/shopping-cart'),
    );
  }

  onContinue() {
    this.paymentService
      .getPaymentMethod(
        Number(this.businessId),
        serviceTypes.Inhouse.name,
        PaymentMethod.Cash,
      )
      .subscribe((cashPaymnetMethod) => {
        if (!this.payFromQrCode && cashPaymnetMethod.isActive) {
          this.openDialog();
        } else {
          this.continueOrder(PaymentMethod.Card);
        }
      });
  }

  continueOrder(paymentMethod: PaymentMethod) {
    if (!this.payFromQrCode && !this.isPayTeaser) {
      this.isLoading = true;

      const name = sessionStorage.getItem(sessionStorageKeys.name) ?? "";
      const phone = sessionStorage.getItem(sessionStorageKeys.phone) ?? "";
      const notes = sessionStorage.getItem(sessionStorageKeys.notes) ?? "";
      const order = new OrderRequest({
        businessId: parseInt(this.businessStore.businessId),
        orderItems: this.cartProducts.map((cartProduct) => {
          return new OrderItemRequest({
            menuId: cartProduct.menuId,
            productId: cartProduct.productId,
            variantId: cartProduct.variant?.variantId || undefined,
            productQuantity: cartProduct.quantity,
            extras: cartProduct.extras.map((extra: any) => {
              extra.extraName = extra.name;
              return OrderItemExtraRequestDto.fromJS(extra);
            }
            ),
            comment: cartProduct.comment ?? '',
          });
        }),
        fullName: name,
        phoneNumber: phone,
        otherNotes: notes,
        serviceTypeName: serviceTypes[this.serviceTypeModule].name,
        qrCodeId: Number(this.businessStore.qrCodeId),
        totalPrice: this.getTotalPriceFn(),
        paymentMethod,
        tipAmount: this.roundAmount(this.totalTipValue),
      });
      if (paymentMethod !== PaymentMethod.Cash) {
        this.businessFacade.putOrder(order).then(() => {
          this.subscribeToOrderResponse();
        });
      } else {
        this.businessFacade.putOrder(order).then(() => {
          this.paymentMethodCash();
        });
      }
    } else {
      this.ordersService
        .addTipsToOrdersByOpenTableId(
          this.openTableId,
          this.roundAmount(this.totalTipValue),
        )
        .subscribe(() => {
          const payrexxGateway = this.paymentService.createPaymentGateway(
            this.businessId,
            this.isPayTeaser ? this.openTableId : '',
            this.businessFacade.generatePaymentUrlsForServiceType(
              'success',
              this.serviceTypeModule,
              this.payFromQrCode,
              this.openTableId,
            ),
            this.businessFacade.generatePaymentUrlsForServiceType(
              'failed',
              this.serviceTypeModule,
            ),
            this.businessFacade.generatePaymentUrlsForServiceType(
              'cancel',
              this.serviceTypeModule,
            ),
            this.openTableId,
          );
          this.businessFacade.saveAppState();
          payrexxGateway.subscribe((response) =>
            this.redirectToPaymentGateway(response.link ? response.link : ''),
          );
        });
    }
  }

  private subscribeToOrderResponse(): void {
    this.businessFacade.orderResponse$.data$
      .pipe(
        filter((response) => response && true),
        take(1),
      )
      .subscribe((response) => {
        const payrexxGateway = this.paymentService.createPaymentGateway(
          this.businessId,
          response.orderNumber,
          this.businessFacade.generatePaymentUrlsForServiceType(
            'success',
            this.serviceTypeModule,
          ),
          this.businessFacade.generatePaymentUrlsForServiceType(
            'failed',
            this.serviceTypeModule,
          ),
          this.businessFacade.generatePaymentUrlsForServiceType(
            'cancel',
            this.serviceTypeModule,
          ),
          undefined,
        );
        this.businessFacade.saveAppState();
        payrexxGateway.subscribe((response) =>
          this.redirectToPaymentGateway(response.link ? response.link : ''),
        );
      });
  }

  private paymentMethodCash(): void {
    this.businessFacade.orderResponse$.data$
      .pipe(
        filter((response) => response && true),
        take(1),
      )
      .subscribe((response) => {
        this.businessFacade.finalizeOrder(response.orderNumber);
        this.businessFacade.saveAppState();
        this.isLoading = false;
        this.businessFacade.goToUrl(
          this._location.path().replace('/tips', '/confirmation'),
        );
      });
  }

  redirectToPaymentGateway(paymentLink: string): void {
    this.isLoading = false;
    window.location.href = paymentLink;
  }

  openPayment(link: string, orderNumber: string): void {
    const element = document.createElement('div');
    element.setAttribute('data-href', link);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    jQuery(element).jampayModal({
      hidden: function (transaction: any) {
        if (Object.keys(transaction).length) {
          component.zone.run(() => {
            if (
              transaction.status !== 'cancelled' &&
              transaction.status !== 'declined'
            ) {
              component.businessFacade.finalizeOrder(orderNumber);
              component.businessFacade.saveAppState();
              component.businessFacade.goToInhouseConfirmationUrl(
                this.payFromQrCode,
                this.openTableId,
              );
            }
          });
        }
      },
    });

    element.click();
  }

  get getTotalPrice(): number {
    return this.getTotalPriceFn() + this.roundAmount(this.totalTipValue);
  }

  roundAmount(amount: number): number {
    return Math.round(amount * 20) / 20;
  }

  getTotalPriceFn(): number {
    let totalPrice = 0;

    this.cartProducts.forEach((cartProduct: CartProduct) => {
      let productTotalPrice = 0;

      if (cartProduct.variant) {
        productTotalPrice += cartProduct.quantity * cartProduct.variant.price;
      } else {
        productTotalPrice += cartProduct.quantity * cartProduct.price;
      }

      cartProduct.extras.forEach((extra: ExtraDto) => {
        if (extra.options) {
          productTotalPrice += extra.options.reduce(
            (priceSummed, extraOption) => {
              return (
                priceSummed +
                extraOption.price * extraOption.quantity * cartProduct.quantity
              );
            },
            0,
          );
        }
      });
      totalPrice += productTotalPrice;
    });

    return totalPrice;
  }

  decreasePercentage() {
    if (this.percentage !== 1) {
      this.percentage = this.percentage - 1;
      this.calculateTip();
    }
  }

  increasePercentage() {
    this.percentage = this.percentage + 1;
    this.calculateTip();
  }
}
