<ng-container
  *ngIf="{
    isNotLoading: businessFacade.business$.isNotLoading$ | async,
    isNotLoadingMenuProducts:
      businessFacade.menuProducts$.isNotLoading$ | async,
    hasNoError: businessFacade.business$.hasNoError$ | async,
    notTakingOrders: businessFacade.business$.isClosed$ | async,
    businessIsOpen: businessFacade.businessIsOpen$ | async,
    address: (businessFacade.business$.data$ | async)?.address,
  } as state"
>
  <div class="home__welcome">
    <ng-container *ngIf="state.isNotLoading && state.hasNoError">
      <orderapp-frontend-home-welcome></orderapp-frontend-home-welcome>
    </ng-container>

    <div class="home__modules">
      <ng-container
        *ngIf="
          state.isNotLoading && state.isNotLoadingMenuProducts;
          else loader
        "
      >
        <ng-container
          *ngIf="
            state.businessIsOpen &&
              !state.notTakingOrders &&
              serviceTypes[0].isEnabled;
            else closed
          "
        >
          <activated-module
            *ngFor="let serviceType of serviceTypes"
            [serviceType]="serviceType"
            [routerLink]="redirectToMenu()"
          ></activated-module>
          <pay-teaser
            *ngIf="showPayTeaser"
            (click)="redirectToPaymentSumary()"
          ></pay-teaser>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="home__address" *ngIf="state.isNotLoading && state.hasNoError">
    <orderapp-frontend-home-address
      [address]="state.address!"
    ></orderapp-frontend-home-address>
  </div>
</ng-container>

<ng-template #closed>
  <p class="home__placeholder">
    {{ 'global.closedText' | translate }}
  </p>
</ng-template>

<ng-template #loader>
  <p class="home__placeholder">{{ 'global.loaderText' | translate }}</p>
  <loader-animation></loader-animation>
</ng-template>
