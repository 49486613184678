import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartProduct } from '../../core/models/cart.model';
import { BusinessFacade } from '../../core/facades/business.facade';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, Subscription, take } from 'rxjs';
import { FormatBusinessName } from '../../shared/utility/format-title';
import { ActivatedModule } from '../../core/enums/activated-module.enum';
import { getServiceModuleByUrl } from '../../shared/utility/get-activated-service-module';
import { serviceTypes } from '../../core/constants/service-type';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BusinessStore } from '../../core/models/business-store.model';
import { environment } from 'apps/orderapp/src/environments/environment';
import { PaymentService } from '../../core/services/payment.service';
import { OrdersService } from '../../core/services/orders.service';
import {
  DeliveryZone,
  OrderRequest,
  PreparationTimeResponse,
} from '@orderapp/api-clients/orderadmin-api-client';
import { sessionStorageKeys } from '../../core/constants/session-storage-keys';
import { PaymentMethod } from '../../core/enums/payment-method.enum';
import { PaymentOption } from '../../core/models/payment-option';
import { PaymentDialogComponent } from '../shopping-cart/payment-dialog/payment-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SWISS_CURRENCY } from '../../core/constants/currencies';

declare let jQuery: any; // Declare the 'jQuery' symbol

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, OnDestroy {
  cartProducts: CartProduct[] = [];
  form: FormGroup = new FormGroup({});
  serviceTypeId: number | undefined = undefined;
  businessId = 0;
  selectedTime = sessionStorage.getItem(sessionStorageKeys.pickUpTime);
  pickupTime: string | null = sessionStorage.getItem(sessionStorageKeys.pickUpTime);
  availableTime: string | null = sessionStorage.getItem(sessionStorageKeys.pickUpTime);
  isInhouse = false;
  serviceTypeModule: ActivatedModule = ActivatedModule.TakeAway;
  private orderResponseSubscription: Subscription | undefined;
  qrCodeId: number | null = null;
  businessStore: BusinessStore = {} as BusinessStore;
  activatedModules = ActivatedModule;

  preparationTimes: PreparationTimeResponse[] = [];
  timesToSelect: string[] = [];
  serviceTypeName = '';
  deliveryCost = 0;
  deliveryZonesToSelect: DeliveryZone[] = [];
  selectedDeliveryZoneId: number | undefined;
  payFromQrCode = false;
  openTableId = '';
  isChargeToRoomPaymentMethodActive: boolean = false;
  currency: string = SWISS_CURRENCY;

  constructor(
    private _router: Router,
    public readonly businessFacade: BusinessFacade,
    private _location: Location,
    private zone: NgZone,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public readonly paymentService: PaymentService,
    private readonly orderService: OrdersService,
    private bottomSheet: MatBottomSheet,
  ) {
    this.serviceTypeModule = getServiceModuleByUrl(location.pathname);
    this.initializeForm();
    this.isInhouse = location.pathname.includes(
      serviceTypes[ActivatedModule.Inhouse].name.toLowerCase(),
    );
    this.businessFacade.cart.subscribe((data) => {
      this.cartProducts = data;
    });
  }

  ngOnInit(): void {
    this.payFromQrCode =
      Boolean(this.route.snapshot.queryParamMap.get('payFromQrCode')) ?? false;
    this.openTableId =
      this.route.snapshot.queryParamMap.get('openTableId') ?? '';
    this.route.params.subscribe((params) => {
      this.handleRouteParams(params as BusinessStore);
    });
    this.subscribeToStore();
    this.subscribeToBusinessData();
    this.fetchPreperationTime();
    if (this.serviceTypeModule === ActivatedModule.Delivery) {
      this.businessFacade.fetchDeliveryZones(
        Number(this.businessStore.businessId),
      );

      this.selectedDeliveryZoneId = Number(
        sessionStorage.getItem(sessionStorageKeys.deliveryZoneId),
      );
    }
    if(this.serviceTypeModule === ActivatedModule.HotelService) {
      this.getIsChargeToRoomPaymentMethodActive(this.businessId);
    }
  }

  getTranslatedMessage(): SafeHtml {
    const translatedText = this.translate.instant(
      'takeaway.selectTimeValidationOrderLimit',
      {
        selectedTime: moment(this.selectedTime).format('HH:mm'),
        availableTime: moment(this.availableTime).format('HH:mm'),
      },
    );
    return this.sanitizer.bypassSecurityTrustHtml(translatedText);
  }

  changeCost(value: number) {
    sessionStorage.setItem(sessionStorageKeys.deliveryZoneCost, `${value}`);
  }

  private fetchPreperationTime() {
    this.businessFacade.store.subscribe((businessStore) => {
      this.businessFacade.fetchPreparationTimes(
        Number(businessStore.businessId),
        this.serviceTypeName,
      );
      this.businessFacade.preparationTimes$.data$.subscribe((res) => {
        this.preparationTimes = res;
        this.updatePickupTimeIfNecessary();
        this.updateTimesToSelect();
      });
    });
  }

  onPickupTimeChange(): void {
    const currentDate = moment().format('YYYY-MM-DD');
    const dateTime = `${currentDate}T${this.pickupTime}`;

    this.availableTime = dateTime;
    sessionStorage.setItem(sessionStorageKeys.pickUpTime, dateTime);
  }

  isPickupTimeStillAvailable(): boolean {
    const selectedTime = moment(this.pickupTime);
    const isToday = selectedTime.isSame(moment(), 'day');
    if (!isToday) {
      return true;
    }

    for (const preparationTime of this.preparationTimes) {
      for (const availableTimeSlot of preparationTime.availableTimeSlots) {
        const availableTime = moment(availableTimeSlot);
        if (selectedTime.isSame(availableTime)) {
          return true;
        }
      }
    }
    return false;
  }

  private findNextAvailableTime(): string | null {
    const allTimeSlots: Date[] = this.preparationTimes
      .reduce((acc: Date[], preparationTime) => {
        return acc.concat(preparationTime.availableTimeSlots);
      }, [])
      .sort();

    const currentPickupTime = moment(this.pickupTime);
    for (const timeSlot of allTimeSlots) {
      const availableTime = moment(timeSlot);
      if (availableTime.isAfter(currentPickupTime)) {
        return availableTime.format();
      }
    }
    return null;
  }

  private updatePickupTimeIfNecessary(): void {
    if (!this.isPickupTimeStillAvailable()) {
      const nextAvailableTime = this.findNextAvailableTime();
      if (nextAvailableTime) {
        this.pickupTime = nextAvailableTime;
      }
    }
  }

  private updateTimesToSelect(): void {
    this.timesToSelect = this.preparationTimes
      .reduce((acc: string[], preparationTime) => {
        const formattedTimeSlots = preparationTime.availableTimeSlots.map(
          (timeSlot) => moment(timeSlot).format('HH:mm'),
        );
        return acc.concat(formattedTimeSlots);
      }, [] as string[])
      .sort();
  }

  private subscribeToStore(): void {
    this.businessFacade.store.subscribe((store) => {
      document.title = FormatBusinessName(
        store.businessName,
        'Kontaktinformationen',
      );
      if (!store.businessId) {
        switch (this.serviceTypeModule) {
          case ActivatedModule.Inhouse:
            this.businessFacade.goToUrl(
              sessionStorage.getItem(sessionStorageKeys.homeUrl) as string,
            );
            break;
          case ActivatedModule.TakeAway:
            this.businessFacade.goToUrl(
              this._router.url.replace('/take-away/checkout', ''),
            );
            break;
          case ActivatedModule.Delivery:
            this.businessFacade.goToUrl(
              this._router.url.replace('/delivery/checkout', ''),
            );
            break;
          case ActivatedModule.HotelService:
            this.businessFacade.goToUrl(
              this._router.url.replace('/checkout', ''),
            );
            break;
          default:
            break;
        }
      }
    });
  }

  private handleRouteParams(params: BusinessStore): void {
    const businessStore = params as BusinessStore;
    this.businessFacade.setBusinessStore(businessStore);
    if (this.serviceTypeModule === ActivatedModule.Inhouse) {
      /* empty */
    }
    let serviceTypeName = serviceTypes.Inhouse.name;
    switch (this.serviceTypeModule) {
      case ActivatedModule.Inhouse:
        sessionStorage.setItem(
          sessionStorageKeys.homeUrl,
          `/${environment.prePath}${businessStore.businessType}/${businessStore.city}/${businessStore.businessName}/${businessStore.businessId}/inhouse/${businessStore.qrCodeId}`,
        );
        serviceTypeName = serviceTypes.Inhouse.name;
        break;
      case ActivatedModule.Delivery:
        serviceTypeName = serviceTypes.Delivery.name;
        break;
      case ActivatedModule.HotelService:
        serviceTypeName = serviceTypes['Hotel Service'].name;
        break;
      case ActivatedModule.TakeAway:
        serviceTypeName = serviceTypes['Take away'].name;
        break;
      default:
        break;
    }
    const cartProducts: CartProduct[] = JSON.parse(
      sessionStorage.getItem(sessionStorageKeys.cartProducts) ?? '[]',
    );
    this.businessFacade.setCart(cartProducts);
    this.businessStore = params as BusinessStore;
    this.businessId = parseInt(this.businessStore.businessId);
    this.serviceTypeName = serviceTypeName;
  }

  private subscribeToBusinessData(): void {
    this.businessFacade.business$.data$.subscribe((data) => {
      if (data) {
        this.serviceTypeId = data.serviceTypes.filter(
          (item) =>
            item.name.toLowerCase() === this.serviceTypeModule.toLowerCase(),
        )[0].serviceTypeId;
      }
    });

    this.businessFacade.store.subscribe((store) => {
      this.businessId = Number(store.businessId);
      this.qrCodeId = Number(store.qrCodeId);
    });
  }

  private subscribeToOrderResponse(): void {
    this.businessFacade.orderResponse$.data$
      .pipe(
        filter((response) => response && !this.isInhouse),
        take(1),
      )
      .subscribe((response) => {
        const payrexxGateway = this.paymentService.createPaymentGateway(
          String(this.businessId),
          response.orderNumber,
          this.businessFacade.generatePaymentUrlsForServiceType(
            'success',
            this.serviceTypeModule,
          ),
          this.businessFacade.generatePaymentUrlsForServiceType(
            'failed',
            this.serviceTypeModule,
          ),
          this.businessFacade.generatePaymentUrlsForServiceType(
            'cancel',
            this.serviceTypeModule,
          ),
          undefined,
        );
        this.businessFacade.saveAppState();
        payrexxGateway.subscribe((response) =>
          this.redirectToPaymentGateway(response.link ? response.link : ''),
        );
      });
  }

  ngOnDestroy(): void {
    if (this.orderResponseSubscription) {
      this.orderResponseSubscription.unsubscribe();
    }
  }

  redirectToPaymentGateway(paymentLink: string): void {
    window.location.href = paymentLink;
  }

  openPayment(link: string, orderNumber: string): void {
    const element = document.createElement('div');
    element.setAttribute('data-href', link);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    jQuery(element).jampayModal({
      hidden: function (transaction: any) {
        if (Object.keys(transaction).length) {
          component.zone.run(() => {
            if (
              transaction.status !== 'cancelled' &&
              transaction.status !== 'declined'
            ) {
              component.businessFacade.finalizeOrder(orderNumber);
              switch (component.serviceTypeModule) {
                case ActivatedModule.TakeAway:
                  component.businessFacade.goToTakeAwayUrl('confirmation');
                  break;
                case ActivatedModule.Delivery:
                  component.businessFacade.goToDeliveryUrl('confirmation');
                  break;
                case ActivatedModule.HotelService:
                  component.businessFacade.goToHotelServiceUrl('confirmation');
                  break;
                default:
                  break;
              }
            }
          });
        }
      },
    });

    element.click();
  }

  initializeForm(): void {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      notes: new FormControl('', []),
    });

    if (this.serviceTypeModule === ActivatedModule.Delivery) {
      this.form.addControl(
        'deliveryAddress',
        new FormControl('', [Validators.required]),
      );
      this.form.addControl(
        'deliveryZone',
        new FormControl('', [Validators.required]),
      );
    }
  }

  onContinue() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    if (this.serviceTypeModule !== ActivatedModule.Inhouse) {
      const order: OrderRequest = this.orderService.createOrder(
        this.businessId,
        this.serviceTypeModule,
        this.cartProducts,
        this.availableTime,
        this.deliveryCost,
        {
          fullName: this.form.get('name')?.value,
          phoneNumber: this.form.get('phone')?.value,
          otherNotes: this.form.get('notes')?.value,
          deliveryAddress:
            this.serviceTypeModule === ActivatedModule.Delivery
              ? this.form.get('deliveryAddress')?.value
              : null,
          deliveryZoneId:
            this.serviceTypeModule === ActivatedModule.Delivery
              ? this.form.get('deliveryZone')?.value
              : null,
          qrCodeId:
            this.serviceTypeModule === ActivatedModule.HotelService
              ? this.qrCodeId
              : null,
        },
      );
      if (this.isChargeToRoomPaymentMethodActive && this.serviceTypeModule === ActivatedModule.HotelService) {
        this.openPaymentDialog(order);
      } else {
        this.orderService.processOrder(
          order,
          this.businessId,
          this.serviceTypeModule,
          this.isInhouse,
        );
      }
    } else {
      sessionStorage.setItem(sessionStorageKeys.name, this.form.get('name')?.value);
      sessionStorage.setItem(sessionStorageKeys.phone, this.form.get('phone')?.value);
      sessionStorage.setItem(sessionStorageKeys.notes, this.form.get('notes')?.value);
      this.businessFacade.goToInhouseTipsUrl(this.payFromQrCode, this.openTableId);
    }
  }

  goBack(): void {
    this.businessFacade.goToUrl(
      this._location.path().replace('/checkout', '/shopping-cart'),
    );
  }

  get getTotalPrice(): number {
    this.deliveryCost = Number(sessionStorage.getItem(sessionStorageKeys.deliveryZoneCost)) || 0;
    return (
      this.orderService.getTotalPriceFn(this.cartProducts) + this.deliveryCost
    );
  }

  openPaymentDialog(order: OrderRequest): void {
    let paymentOptions = [{
      method: PaymentMethod.Card,
      translationKey: 'paymentmethod.online'
    },
    {
      method: PaymentMethod.ChargeToRoom,
      translationKey: 'paymentMethod.ChargeToRoomLabel'
    }] as PaymentOption[];

    const bottomSheetRef = this.bottomSheet.open(PaymentDialogComponent, {
      data: paymentOptions,
    });

    bottomSheetRef.afterDismissed().subscribe((result) => {
      this.continueOrder(order, result.paymentMethod);
    });
  }

  continueOrder(order: OrderRequest, paymentMethod: PaymentMethod): void {
    order.paymentMethod = paymentMethod;
    if (paymentMethod !== PaymentMethod.ChargeToRoom) {
      this.businessFacade.putOrder(order).then(() => {
        this.subscribeToOrderResponse();
      });
    } else {
      this.businessFacade.putOrder(order).then(() => {
        this.paymentMethodChargeToRoom();
      });
    }
  }

  private paymentMethodChargeToRoom(): void {
    this.businessFacade.orderResponse$.data$
      .pipe(
        filter((response) => response && true),
        take(1),
      )
      .subscribe((response) => {
        this.businessFacade.finalizeOrder(response.orderNumber);
        this.businessFacade.saveAppState();
        this.businessFacade.goToUrl(
          this._location.path().replace('/checkout', '/confirmation'),
        );
      });
  }

  getIsChargeToRoomPaymentMethodActive(businessId: number) {
    this.paymentService
      .getPaymentMethod(
        businessId,
        serviceTypes['Hotel Service'].name,
        PaymentMethod.ChargeToRoom,
      )
      .subscribe((res) => {
        this.isChargeToRoomPaymentMethodActive = res?.isActive ?? false;
      });
  }
}
