import { Component, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { PaymentMethod } from '../../../core/enums/payment-method.enum';
import { PaymentOption } from '../../../core/models/payment-option';

@Component({
  selector: 'payment-dialog',
  templateUrl: 'payment-dialog.component.html',
  styleUrls: ['payment-dialog.component.scss'],
})
export class PaymentDialogComponent {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<PaymentDialogComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: PaymentOption[],
  ) 
  {}

  closeDialog(paymentMethod: PaymentMethod): void {
    this.bottomSheetRef.dismiss({ paymentMethod });
  }
}
