import { Component, OnDestroy } from '@angular/core';
import { ActivatedModule } from '../../../core/enums/activated-module.enum';
import { BusinessStore } from '../../../core/models/business-store.model';
import { Subject, takeUntil } from 'rxjs';
import { BusinessFacade } from '../../../core/facades/business.facade';
import { HelperService } from '../../../core/services/helper.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { FormatBusinessName } from '../../../shared/utility/format-title';
import { BusinessServiceTypeResponseModel } from '@orderapp/api-clients/orderadmin-api-client';
import { sessionStorageKeys } from '../../../core/constants/session-storage-keys';

@Component({
  selector: 'orderapp-frontend-take-away',
  templateUrl: './take-away.component.html',
  styleUrls: ['./take-away.component.scss'],
})
export class HomeTakeAwayComponent implements OnDestroy {
  public readonly modules = [
    ActivatedModule.TakeAway,
    ActivatedModule.Delivery,
  ];

  public ActivatedModule = ActivatedModule;
  private readonly _destroy$ = new Subject();
  businessStore: BusinessStore = {} as BusinessStore;
  isInhouse = false;
  serviceTypes!: BusinessServiceTypeResponseModel[];
  isOpen = false;
  deliveryIsPaused = false;
  takeAwayIsPaused = false;

  deliveryIsPaused$ = this.businessFacade.deliveryIsPaused$;
  takeAwayIsPaused$ = this.businessFacade.takeAwayIsPaused$;

  constructor(
    public readonly businessFacade: BusinessFacade,
    private readonly _route: ActivatedRoute,
    public readonly helperService: HelperService,
  ) {
    this._route.params.pipe(takeUntil(this._destroy$)).subscribe({
      next: (params) => {
        const businessStore = params as BusinessStore;
        this.businessFacade.setBusinessStore(businessStore);
        this.businessFacade.serviceTypes$.data$.subscribe((item) => {
          this.serviceTypes = item?.filter(
            (module) =>
              module.name?.toLowerCase() ===
                ActivatedModule.TakeAway.toLowerCase() ||
              module.name?.toLowerCase() ===
                ActivatedModule.Delivery.toLowerCase(),
          );
          this.isOpen = this.serviceTypes.some((service) => service.isEnabled);
          const bothPaused = this.serviceTypes.every(
            (service) => service.isPaused,
          );
          if (bothPaused) {
            this.deliveryIsPaused = true;
            this.takeAwayIsPaused = true;
          }
        });
        this.deliveryIsPaused$.subscribe((isPaused) => {
          this.deliveryIsPaused = isPaused;
        });

        this.takeAwayIsPaused$.subscribe((isPaused) => {
          this.takeAwayIsPaused = isPaused;
        });

        this.isInhouse = !!params['qrCodeId'];
        this.businessStore = businessStore;
        sessionStorage.setItem(
          sessionStorageKeys.homeUrl,
          `/${environment.prePath}${businessStore.businessType}/${businessStore.city}/${businessStore.businessName}/${businessStore.businessId}`,
        );

        document.title = FormatBusinessName(
          businessStore.businessName,
          'Abholen',
        );
      },
    });
    this.businessFacade.resetCart();
  }

  getServiceLink(serviceType: string): string {
    return `/${environment.prePath}${this.businessStore.businessType}/${
      this.businessStore.city
    }/${this.businessStore.businessName}/${
      this.businessStore.businessId
    }/${this.helperService.toSeoUrl(serviceType)}`;
  }

  public ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  hideModules(serviceType: any): void {
    return (
      (serviceType.isEnabled &&
        serviceType.name.toLocaleLowerCase() !==
          ActivatedModule.TakeAway.toLowerCase()) ||
      (serviceType.name.toLocaleLowerCase() ===
        ActivatedModule.TakeAway.toLowerCase() &&
        serviceType.name.toLocaleLowerCase() ===
          ActivatedModule.Delivery.toLocaleLowerCase())
    );
  }
}
