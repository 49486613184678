import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessFacade } from '../../core/facades/business.facade';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItemsDetailsComponent } from '../../shared/components/menu/menu-items/menu-items-details/menu-items-details.component';
import { MatDialog } from '@angular/material/dialog';
import { filter, Subject, switchMap, take, takeUntil } from 'rxjs';
import { BusinessStore } from '../../core/models/business-store.model';
import { environment } from '../../../environments/environment';
import { FormatBusinessName } from '../../shared/utility/format-title';
import { Title } from '@angular/platform-browser';
import { ActivatedModule } from '../../core/enums/activated-module.enum';
import { getServiceModuleByUrl } from '../../shared/utility/get-activated-service-module';
import { serviceTypes } from '../../core/constants/service-type';
import { CartProduct } from '../../core/models/cart.model';
import {
  BusinessServiceTypeResponseModel,
  MenuProductsResponseModel,
  MenusWithProductsResponseModel,
  MenuWithProductsResponseModel,
} from '@orderapp/api-clients/orderadmin-api-client';
import { RouteService } from '../../core/services/router.service';
import { sessionStorageKeys } from '../../core/constants/session-storage-keys';

@Component({
  selector: 'orderapp-frontend-food-menu',
  templateUrl: './beverage-menu.component.html',
  styleUrls: ['./beverage-menu.component.scss'],
})
export class BeverageMenuComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  menuProducts: MenuWithProductsResponseModel =
    new MenuWithProductsResponseModel();
  selectedMenuId: number = 0;
  selectedMenu = new MenuWithProductsResponseModel();
  businessStore: BusinessStore = {} as BusinessStore;
  serviceTypeModule: ActivatedModule = ActivatedModule.TakeAway;
  serviceType: BusinessServiceTypeResponseModel[] = [];
  menusOptions: MenuWithProductsResponseModel[] | undefined = [];
  showCategoryMenu: boolean = false;

  constructor(
    public readonly businessFacade: BusinessFacade,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    private titleService: Title,
    private routeService: RouteService,
  ) {}

  ngOnInit(): void {
    this.serviceTypeModule = getServiceModuleByUrl(this.location.path());
    this.checkIfModuleIsEnable();
    this.route.params.forEach((res) => {
      this.titleService.setTitle(
        FormatBusinessName(res['businessName'], 'Getränkekarte'),
      );
    });
    this.businessFacade.menuProducts$.data$
      .pipe(
        take(1),
        filter((menusWithProducts) => !menusWithProducts),
        switchMap(() => this.route.params.pipe(takeUntil(this.destroy$))),
      )
      .subscribe((params) => {
        this.handleRouteParams(params as BusinessStore);
      });

    this.businessFacade.store
      .pipe(
        filter((store) => !store.businessId),
        takeUntil(this.destroy$),
      )
      .subscribe((store: any) => {
        this.handleNoBusinessId();
      });

    this.businessFacade.menuProducts$.data$
      .pipe(takeUntil(this.destroy$))
      .subscribe((menuProducts) => {
        this.handleMenuProducts(menuProducts);
      });
  }

  checkIfModuleIsEnable() {
    this.businessFacade.serviceTypes$.data$.subscribe((serviceType) => {
      this.serviceType = serviceType?.filter(
        (module) => module.name === this.serviceTypeModule,
      );
      if (this.serviceType?.length && !this.serviceType[0].isEnabled) {
        this.businessFacade.goToHome(true, this.serviceTypeModule);
      }
    });
  }

  private handleRouteParams(params: BusinessStore): void {
    const businessStore = params as BusinessStore;
    this.businessFacade.setBusinessStore(businessStore);
    if (this.serviceTypeModule === ActivatedModule.Inhouse) {
      /* empty */
    }
    let serviceTypeName = serviceTypes.Inhouse.name;
    switch (this.serviceTypeModule) {
      case ActivatedModule.Inhouse:
        sessionStorage.setItem(
          sessionStorageKeys.homeUrl,
          `/${environment.prePath}${businessStore.businessType}/${businessStore.city}/${businessStore.businessName}/${businessStore.businessId}/inhouse/${businessStore.qrCodeId}`,
        );
        serviceTypeName = serviceTypes.Inhouse.name;
        break;
      case ActivatedModule.Delivery:
        serviceTypeName = serviceTypes.Delivery.name;
        break;
      case ActivatedModule.HotelService:
        serviceTypeName = serviceTypes['Hotel Service'].name;
        break;
      case ActivatedModule.TakeAway:
        if (this.businessStore.qrCodeId != null) {
          sessionStorage.setItem(
            sessionStorageKeys.homeUrl,
            `/${environment.prePath}${businessStore.businessType}/${businessStore.city}/${businessStore.businessName}/${businessStore.businessId}/take-away/${businessStore.qrCodeId}`,
          );
        }
        serviceTypeName = serviceTypes['Take away'].name;
        break;
      default:
        break;
    }
    this.businessFacade.fetchOrderingTimes(
      Number(businessStore.businessId),
      serviceTypeName,
    );
    this.businessFacade.fetchPreparationTimes(
      Number(businessStore.businessId),
      serviceTypeName,
    );
    this.businessFacade.fetchMenuProducts(
      Number(businessStore.businessId),
      this.serviceTypeModule,
      Number(businessStore.qrCodeId) || null,
    );
    const cartProducts: CartProduct[] = JSON.parse(
      sessionStorage.getItem(sessionStorageKeys.cartProducts) ?? '[]',
    );
    this.businessFacade.setCart(cartProducts);
    this.businessStore = params as BusinessStore;
  }

  private handleNoBusinessId(): void {
    switch (this.serviceTypeModule) {
      case ActivatedModule.Inhouse:
        this.businessFacade.goToUrl(
          sessionStorage.getItem(sessionStorageKeys.homeUrl) as string,
        );
        break;
      case ActivatedModule.TakeAway:
        if (this.businessStore.qrCodeId != null) {
          this.businessFacade.goToUrl(
            this.router.url.replace(
              '/take-away/' + this.businessStore.qrCodeId + '/beverage-menu',
              '',
            ),
          );
        } else {
          this.businessFacade.goToUrl(
            this.router.url.replace('/take-away/beverage-menu', ''),
          );
        }
        break;
      case ActivatedModule.Delivery:
        this.businessFacade.goToUrl(
          this.router.url.replace('/delivery/beverage-menu', ''),
        );
        break;
      case ActivatedModule.HotelService:
        this.businessFacade.goToUrl(
          this.router.url.replace('/beverage-menu', ''),
        );
        break;
      default:
        break;
    }
  }

  private handleMenuProducts(
    menusWithProducts: MenusWithProductsResponseModel,
  ): void {
    if (!sessionStorage.getItem(sessionStorageKeys.menuId)) {
      sessionStorage.setItem(
        sessionStorageKeys.menuId,
        menusWithProducts?.menus?.[0].menuId?.toString() ?? '0',
      );
    }
    if (menusWithProducts?.menus && menusWithProducts?.menus?.length == 1) {
      if (menusWithProducts?.menus?.[0]?.beverageProducts?.length === 0) {
        switch (this.serviceTypeModule) {
          case ActivatedModule.Inhouse:
            this.businessFacade.goToUrl(
              sessionStorage.getItem(sessionStorageKeys.homeUrl) as string,
            );
            break;
          case ActivatedModule.TakeAway:
            this.businessFacade.goToUrl(
              this.router.url.replace('/take-away/beverage-menu', ''),
            );
            break;
          case ActivatedModule.Delivery:
            this.businessFacade.goToUrl(
              this.router.url.replace('/delivery/beverage-menu', ''),
            );
            break;
          case ActivatedModule.HotelService:
            this.businessFacade.goToUrl(
              this.router.url.replace('/beverage-menu', ''),
            );
            break;
          default:
            break;
        }
      } else {
        this.businessFacade.selectedMenuid$.subscribe((selectedMenuId) => {
          this.selectedMenuId =
            selectedMenuId !== 0
              ? selectedMenuId
              : Number(
                  sessionStorage.getItem(sessionStorageKeys.menuId) || '0',
                );
          const selectedMenu = menusWithProducts.menus?.filter(
            (x) => x.menuId === this.selectedMenuId,
          )[0];
          if (selectedMenu) {
            this.menuProducts = selectedMenu;
            this.showCategoryMenu = selectedMenu.beverageProducts.length > 1;
          }
        });
      }
    } else {
      this.businessFacade.selectedMenuid$.subscribe((selectedMenuId) => {
        this.selectedMenuId =
          selectedMenuId !== 0
            ? selectedMenuId
            : Number(sessionStorage.getItem(sessionStorageKeys.menuId) || '0');
        const selectedMenu = menusWithProducts?.menus?.filter(
          (x) => x.menuId === this.selectedMenuId,
        )[0];
        if (selectedMenu?.beverageProducts.length == 0) {
          this.goBack();
        }
        if (selectedMenu) {
          this.menuProducts = selectedMenu;
          this.showCategoryMenu = selectedMenu.beverageProducts.length > 1;
        }
        this.menusOptions = menusWithProducts?.menus?.filter(
          (x) => x.beverageProducts.length > 0,
        );
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goBack(): void {
    this.businessFacade.goToUrl(
      this.router.url.replace('/beverage-menu', '/food-menu'),
    );
  }

  openBottomSheet(): void {
    this.dialog.open(MenuItemsDetailsComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '700px',
    });
  }

  onSelectedMenuChange() {
    this.businessFacade.setSelectedMenuId(this.selectedMenuId);
    sessionStorage.setItem(
      sessionStorageKeys.menuId,
      this.selectedMenuId.toString(),
    );
  }
}
