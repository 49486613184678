<div class="container">
  <p class="container__title" mat-dialog-title>
    {{ 'paymentmethod.title' | translate }}
  </p>
  <hr />
  <div mat-dialog-content style="padding-bottom: 15px">
    <div *ngFor="let option of data">
      <button (click)="closeDialog(option.method)" class="container__content">
        {{ option.translationKey | translate }}
      </button>
      <hr />
    </div>
  </div>
</div>