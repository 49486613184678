<ng-container>
  <h1 class="home__welcome--title">{{ 'home.welcome' | translate }}</h1>
  <p class="home__welcome--subtitle">
    {{ 'home.orderFewStep' | translate }}
  </p>
  <p class="home__welcome--subtitle" *ngIf="!isOpen">
    {{ 'global.closedText' | translate }}
  </p>
  <p class="home__welcome--subtitle" *ngIf="takeAwayIsPaused && deliveryIsPaused && isOpen">
    {{ 'pausedBothModules' | translate }}
  </p>
</ng-container>