<section class="menu-items__list" [id]="category.name.toLowerCase()">
  <div class="menu-items__header">
    <i
      [class]="category.icon"
      style="color: var(--primary-color); font-size: 32px"
    ></i>
    <p class="menu-items__header-category-name">{{ category.name }}</p>
  </div>
  <div
    class="menu-items__item"
    *ngFor="let item of category.products; let i = index"
    (click)="openDetailsDialog(i)"
  >
    <div class="menu-items__item-header">
      <p class="menu-items__item-title">
        <span
          class="primary-color"
          *ngIf="getProductQuantityOnCart(item.product?.productId)"
          >{{ getProductQuantityOnCart(item.product?.productId) }} x </span
        >{{ item.product?.name }}
      </p>
      <div class="menu-items__item-properties" *ngIf="hasProperties(i)">
        <img
          *ngFor="let item of getProperties(i)"
          class="colorize"
          [src]="item.icon"
          [alt]="item.icon"
        />
      </div>
    </div>
    <div class="menu-items__item-footer">
      <p class="menu-items__item-description long_text">
        {{ item.product?.description }}
      </p>
      <p class="menu-items__item-price">
        CHF {{ getEffectivePrice(item.product?.variants || []) | number: '1.2-2' }}
      </p>
    </div>
  </div>
  <div class="bottom-button-container" *ngIf="showBottomButton">
    <button (click)="onContinue()" [disabled]="isContinueButtonDisabled()">
      {{
        foodTypeId === 1 && hasBeverageProducts
          ? ('beveragemenu.title' | translate)
          : ('global.shoppingCartButton' | translate)
      }}
      <span *ngIf="cartProducts.length > 0"
        >- {{ currency }} {{ getTotalPrice | number: '1.2-2' }}</span
      >
    </button>
  </div>
</section>
